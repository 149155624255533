import { ref } from "vue"
import { useUpdateCustomerDataNew } from '@/store/composable/Customer'
import { useUser, useRoleBasedConditions } from '@/store/composable/User'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setBreadCrumbs, setFromBreadCrumbs } from '@/store/composable/BreadCrumbs'

const { role_id } = useUser()
export async function useListing(filters, route_name) {
    const roles = useRoleBasedConditions()

    if(route_name && !roles[conditions(route_name)] && role_id) window.location.href = '/#/404'
    
        const params = {
            linkType: route_name,
            ...filters
        }
        let breadcrumb = setBreadCrumbs(params.linkType)
        let fromBreadcrumb = setFromBreadCrumbs(params.linkType)
        setCurrentPageBreadcrumbs(breadcrumb, fromBreadcrumb)
        const response = await useUpdateCustomerDataNew(params)
        return response
}

export const marketingSpendP = ref({
    campaign: "",
    year_month: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0'),
    year: new Date().getFullYear(),
})

export const marketingReportP = ref({
    keyword: "",
    start_date: "",
    end_date: "",
    agent: "",
    export: false
})

export const dateRanges = ref([])
export const search = ref('')

function conditions(route_name) {
    switch(route_name) {
        case 'marketing-spend':
        case 'marketing-spend-tracking': return 'pMarketingSpend'
        case 'marketing-report':
        case 'marketing-report-agent':
        case 'get-active-inactive-agents': return 'pMarketingReport'
        
        case 'renewal-status': return 'adminManager'
        case 'lost-lead-report': return 'adminManager'
        case 'agent-escalations': return 'adminManager'
        case 'underwriter-escalations': return 'underwriterEscalations'
        case 'sales-tracking-report': return 'pSalesTrackingReport'
        case 'agent-lead-activity-report': return 'pAgentLeadActivityReport'
        default: return ''
    }
}